import React from 'react'
import { graphql } from 'gatsby'

interface Props {
  data: {
    markdownRemark: {
      html: string
    }
  }
}

export default function CorsResource(props: Props) {
  return (
    <div
      id="cors-resource"
      dangerouslySetInnerHTML={{
        __html: props.data.markdownRemark.html,
      }}
    />
  )
}

export const pageQuery = graphql`
  query CorsResource(
    $productId: String
    $version: String
    $locale: String
    $pageId: String
  ) {
    markdownRemark(
      fields: {
        productId: { eq: $productId }
        version: { eq: $version }
        locale: { eq: $locale }
        pageId: { eq: $pageId }
      }
    ) {
      html
    }
  }
`
